
import { computed, reactive, watchEffect, watch } from "vue";
import { useStore } from "vuex";

import ContentItem from "@/components/ContentItem.vue";

const perPage = 16;

export default {
  components: {
    ContentItem,
  },
  setup() {
    const store = useStore();

    const curPage = reactive({ value: 0 });

    const particles = computed(() => {
      const output = store.state.queryList.slice(
        curPage.value * perPage,
        curPage.value * perPage + perPage
      );

      return output;
    });

    watchEffect(() => {
      if (store.state.queryList) {
        curPage.value = 0;
      }
    });

    function next() {
      if (store.state.queryList.length - (curPage.value + 1) * perPage <= 0) {
        return;
      }

      curPage.value++;
    }

    function prev() {
      if (curPage.value == 0) {
        return;
      }

      curPage.value--;
    }

    return {
      store,
      particles,
      prev,
      next,
    };
  },
};
