import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx, _cache) {
  const _component_Header = _resolveComponent("Header")
  const _component_router_view = _resolveComponent("router-view")

  return (_openBlock(), _createBlock(_Fragment, null, [
    _createVNode(_component_Header),
    _createVNode(_component_router_view)
  ], 64 /* STABLE_FRAGMENT */))
}