// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_GET_URL_IMPORT___ = require("../node_modules/css-loader/dist/runtime/getUrl.js");
var ___CSS_LOADER_URL_IMPORT_0___ = require("./assets/back.png");
exports = ___CSS_LOADER_API_IMPORT___(false);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
exports.push([module.id, "body,html{padding:0;margin:0;width:100%;height:100%}body{background:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") no-repeat center/cover}#app{display:flex;flex-direction:column;font-family:\"Roboto\",-apple-system,BlinkMacSystemFont,Helvetica,sans-serif;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;color:#fff;width:100%;height:100%}", ""]);
// Exports
module.exports = exports;
