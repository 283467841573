import { reactive, watchEffect } from "vue";
import { useStore } from "vuex";
import SidebarItem from "@/components/SidebarItem.vue";
export default {
    components: {
        SidebarItem,
    },
    setup() {
        const store = useStore();
        const search = reactive({ value: null });
        const selected = reactive({ value: "" });
        watchEffect(() => {
            store.dispatch("search", search.value);
        });
        function selectDict(dict) {
            if (selected.value == dict) {
                store.dispatch("dict", null);
                selected.value = "";
                return;
            }
            selected.value = dict;
            store.dispatch("dict", dict);
        }
        return {
            store,
            search,
            selectDict,
            selected,
        };
    },
};
