import Sidebar from "@/components/Sidebar.vue";
import Content from "@/components/Content.vue";
export default {
    components: {
        Sidebar,
        Content,
    },
    setup(props, context) {
        // const store = useStore();
        // console.log(store);
    },
};
