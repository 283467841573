import { reactive, ref } from "vue";
export default {
    props: {
        id: {
            type: Number,
            required: true,
        },
        dict: {
            type: String,
            required: true,
        },
        asset: {
            type: String,
            required: true,
        },
    },
    setup() {
        let isPlaying = reactive({ value: false });
        let video = ref(null);
        function play() {
            if (isPlaying.value) {
                return;
            }
            video.value.play();
            isPlaying.value = true;
        }
        function stop() {
            if (!isPlaying.value) {
                return;
            }
            video.value.pause();
            video.value.currentTime = 0;
            isPlaying.value = false;
        }
        function copy(str) {
            const el = document.createElement("textarea");
            el.value = str;
            document.body.appendChild(el);
            el.select();
            document.execCommand("copy");
            document.body.removeChild(el);
        }
        return {
            isPlaying,
            play,
            stop,
            video,
            copy,
        };
    },
};
